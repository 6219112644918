<template>
  <div class="corpo">
    
	<div class='logo'>
			<img src="./../assets/img/logo.png" alt="APP Dívida Aberta" 
			style="margin: auto;
  			display: block;padding:5px;width:18%;height:18%;">
		
	</div>
	
	<BR/>
	<div class="flex">
		<div class="warning-panel">
			Você ainda não tem o App Dívida Aberta instalado!
		</div>
		
	</div>
	
	<div class="flex">
		<p>
			<b>Siga os passos abaixo para a efetuar a instalação do App e abrir o link:</b>
		</p>
		<hr>
	</div>
	<div class="card-passos">
		<ul class="list-group list-group-flush">
			<li class="list-group-item">
				<!--div class="flex"-->
					<p>
					Passo 1 - Clique na imagem abaixo para instalar o aplicativo da loja oficial:
					<img src="./../assets/img/googleplay_new.png" @click="openGooglePlay" 
					v-if="isMobile == 'Android' || isMobile == 'unknown'" 
					style="margin: auto;display: block;padding:5px;width:60%;height:60%;"> 
					
					<img src="./../assets/img/appstore.png" @click="openAppStore" 
					v-if="isMobile =='iOS' || isMobile == 'unknown'" 
					style="margin: auto;display: block;padding:5px;width:60%;height:60%;">
					</p>
				<!--/div-->
			</li>
			<li class="list-group-item">
				<!--div class="flex"-->
				<p>
				Passo 2 - Após a instalação, o app apresentará a tela com os dados compartilhados.
				</p>
				<!-- <div>
					<b-button @click="openApp" variant="warning">CLIQUE AQUI para abrir o link</b-button>
				</div> -->
			</li>
			<li class="list-group-item">
				<p>
				Passo 3 - Caso o app não consiga abrir o link após a instalação, 
				clique novamente no link compartilhado. 
				</p>
				<b>(Opcional)</b>
			</li>
		</ul>
	</div>
  </div>
 
</template>

<script>
import { Icon } from '@iconify/vue';


function fallbackCopyTextToClipboard(text) {
  var textArea = document.createElement('textarea');
  textArea.value = text;
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  try {
	//alert(" fallbackCopyTextToClipboard antes copy:: " + text);
    document.execCommand('copy');
  } catch (err) {
	//alert(" fallbackCopyTextToClipboard err:: " + err);
  }
  //alert(" fallbackCopyTextToClipboard textArea:: " + textArea);
  document.body.removeChild(textArea);
}

async function copyTextToClipboard(text) {
	
  if (!navigator.clipboard) {
	//alert(" clipboard 1" + text);
    fallbackCopyTextToClipboard(text);
    return new Promise((r) => setTimeout(r, 250));
  }
  //alert(" clipboard 2" + text);
  return navigator.clipboard.writeText(text);
}

export default {
	name: 'LojasApps',
	components: {
		Icon,
	},
  	props:["dataAtual", "dataManutencao","mesAtual" ],
    data(){
		return {
			isMobile : ''
		}
	},
	mounted() {
        this.isMobile = this.getMobileSystem();
    },
	methods: {
		openApp(e){
			e.preventDefault();
			// const inviteLink = 'https://des.pgfndevedores.estaleiro.serpro.gov.br/pagelink/?CU5vHQ7';
			// copyTextToClipboard(inviteLink).then(() => {
			// 	window.location.href = e.target.href;
			// });
			const params = window.location.search;
			const deeplink = 'dividaaberta://pagelink/' + params;
			//alert(deeplink);
			window.location.href = deeplink;
		},	
		openGooglePlay (e) {
			e.preventDefault();
			const inviteLink = window.location.href;
			copyTextToClipboard(inviteLink).then(() => {
				window.location.href= "https://play.google.com/store/apps/details?id=br.gov.serpro.pgfn.devedores";
			});
		},
		openAppStore(e) {
			e.preventDefault();
			const inviteLink = window.location.href;
			copyTextToClipboard(inviteLink).then(() => {
				window.location.href= "https://apps.apple.com/br/app/dívida-aberta/id1485407516"
			})
		},
		redirect () {
			const ua = navigator.userAgent
			//alert(ua)
			if (/android/i.test(ua)) {
				return "Android"
			}
			else if (/iPad|iPhone|iPod/.test(ua)){
				return "iOS"
			}
		
			const os = ua;
			navigator.clipboard.writeText(window.location.href);
			if (os === 'Android') {
				//window.location.href= "market://details?id=<packagename>";
				window.location.href= "https://play.google.com/store/apps/details?id=br.gov.serpro.pgfn.devedores";
			} else if (os === 'iOS') {
				window.location.href= "https://apps.apple.com/br/app/dívida-aberta/id1485407516"
			} else {
				window.location.href= "http://localhost:8080/"
			}
		},
		getMobileSystem() {
			var userAgent = navigator.userAgent || navigator.vendor || window.opera;	

			if (/android/i.test(userAgent)) {
				//alert("Android");
				return "Android";
			}
			if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
				return "iOS";
				
			}
			return "unknown";
		}
	}
	
}

</script>

<style scoped>

.warning-panel {
  background-color: #fdeed5;
  color: #cc6600;
  border: 1px solid #cc6600;
  border-radius: 5px;
  position: relative;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.corpo {
  background-color: #F0F5F0;
  font-family: 'Nunito Sans', 'sans-serif';
  align-content: center;
  text-align: center;
  height: auto;
  font-family: 'Nunito Sans', sans-serif;
}

span {
	font-family: 'Nunito Sans', 'sans-serif';
	font-size: 1.5rem;
	color: #5466dd;
	text-align: center;
}

p {
   margin:8px;
   text-align: left;
   font-size: 1.2rem;
   font-family: 'Nunito Sans', sans-serif;
}

h1 {
   padding:10px;
   text-align: left;
   font-weight: bold;
   font-size: 3.0rem;
   color: #5466dd;
   margin: 10px 0 0;
   font-family: 'Nunito Sans', sans-serif;
   text-align: center;
}

h2 {
  font-weight: bold;
  padding:10px;
  text-align: center;
  margin: 5px 0 0;
  font-size: 1.5rem;
  font-family: 'Nunito Sans', sans-serif;
}

h6 {
  border-bottom: 1px solid;
  text-transform: uppercase;
  font-size: 0.8125rem;
}

h3 {
  padding:10px;
  text-align: center;
  margin: 5px 0 0;
  font-size: 2.0rem;
  font-family: 'Nunito Sans', sans-serif;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #6677ff;
}
hr {
  width:100%;
  background-color: #6677ff; 
  margin: 1px;

}	
	
.logo {
	background-color: white; 
	width:100%;
	height:50%;
}
.acesse {
	background-color: white; 
	width:20%;
	height:20%;
	margin-top: 17PX;
}
.status {
    background-color: white; 
	width:10%;
	height:10%;
	margin-top: 17PX;
	margin-left: 20px;
}
.iconeManutencao {
    background-color: #F0F5F0; 
	margin-top: 1PX;
	margin-left: 2px;
	width:5px;
	height:5px;
}
.data {
	background-color: #F0F5F0;
	width:70%;
	height:40%;
	margin-top: 20PX;
	text-align: center;
	padding-left:10px;
	font-family: 'Nunito Sans', sans-serif;
}
.atualizado {
    background-color: #F0F5F0;
	width:30%;
	height:40%;
	margin-top:17PX;
	text-align: center;
	padding-left:10px;
	font-family: 'Nunito Sans', sans-serif;
}
.relogio {
	background-color: #F0F5F0; 
    margin-top: 15PX;
	text-align: left;
	padding-left:20px;
	font-family: 'Nunito Sans', sans-serif;
}
.calendario {
	background-color: #F0F5F0;
    margin-top: 16PX;
	text-align: center;
	padding-left:20px;
	font-family: 'Nunito Sans', sans-serif;
}
.flex {
	background-color: #F0F5F0; 
	margin-left: 20px;
	margin-right:20px;
	display: block;
	padding-left: 32px;
	padding-right: 20px;
	padding-bottom: 10px;
	padding-top: 10px;
	font-family: 'Nunito Sans', sans-serif;
	
}
.card-passos {
	margin-left: 20px;
	margin-right:17px;
	padding-left: 22px;
	padding-right: 15px;
	padding-bottom: 10px;
	padding-top: 10px;
	font-family: 'Nunito Sans', sans-serif;
	
}
.flex-logo {
	background-color: #F0F5F0; 
    display: flex;
	box-shadow: 0 1PX 2PX 0 rgba(0, 0, 0, 0.1);
	border-radius: 0.1em;
	margin-bottom: 12PX;
	align-self: center;
	padding-left: 60px;
	font-family: 'Nunito Sans', sans-serif;
	align-content: center;
	text-align: center;
}



</style>
