import { createApp } from 'vue'
import App from './App.vue'
import { createRouter, createWebHistory } from "vue-router";
import LojasApps from "./components/LojasApps";
//import WellKnown from "./components/WellKnown.vue"
import BootstrapVue3 from 'bootstrap-vue-3'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'

const app = createApp(App)

const router = createRouter({
    history: createWebHistory(),
    routes: [
      {
        path: "/",
        component: LojasApps,
      },
      {
        path: "/:",
        component: LojasApps,
      },
      {
        path: "/pagelink/:",
        component: LojasApps,
      },
      // {
      //   path: '/.well-known/apple-app-site-association',
      //   component: WellKnown,
      //   props: {
      //     file: '.well-known/apple-app-site-association'
      //   }
      // },
      { 
        path: '/:pathMatch(.*)*',
        redirect: "/",
      },
      {
        path: '/pathMatch(.*)*',
        name: '404',
        component: () => import('./components/LojasApps')
      },
    ],
});
app.use(router);
app.use(BootstrapVue3)
app.mount('#app')
